import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../utils/authContext';

const AccessControl = ({ children, path }) => {
    const { userData } = useAuth();  // Get user data from the context
    const userMenu = userData?.user_menu;  // Extract user menu

    // Function to check if a path exactly matches the menu path or any child path
    const hasAccess = userMenu && userMenu.some(menuItem => {
        // Check if the current path exactly matches the menu path
        if (path === menuItem.path) {
            return true;
        }
        // If the menu item has children, check if the current path matches any child path exactly
        if (menuItem.children) {
            return menuItem.children.some(child => path === child.path);
        }
        return false;
    });

    if (!hasAccess) {
        return <Navigate to="/unauthorized" replace />;  // Redirect if access is not granted
    }

    return children;  // Render the child component if access is granted
};

export default AccessControl;