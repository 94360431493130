import { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/authContext";

const Sidebar = ({ isVisible, setPageTitle }) => {
    const { account, userData, login, logout } = useAuth(); // Get authentication details from context
    const location = useLocation(); // Get current location to highlight active links
    const [menuItems, setMenuItems] = useState([]); // Store fetched menu items

    // Function to determine if the link is active based on the current path
    const isActiveLink = useCallback((path) => {
        const currentPath = location.pathname.endsWith('/') 
            ? location.pathname.slice(0, -1) // Remove trailing slash for consistency
            : location.pathname;
        return currentPath === path;
    }, [location.pathname]);

    // Fetch menu items from API on initial render
    useEffect(() => {
        if (userData?.user_menu) {
            const dashboardMenu = userData.user_menu.find(menu => menu.label === "Dashboard");
            const dynamicMenuItems = dashboardMenu?.children || [];
            
            // Add Home and Dashboard statically
            const defaultMenuItems = [
                { name: "Home", path: "/" },
                { name: "Dashboard", path: "/dashboard" },
            ];

            setMenuItems([...defaultMenuItems, ...dynamicMenuItems]);
        }
    }, [userData]);

    // Update active menu and page title based on the current path
    useEffect(() => {
        const currentMenu = menuItems.find(item => isActiveLink(item.path)); // Find the active menu item
        
        if (currentMenu && currentMenu.name) {
            setPageTitle(currentMenu.title || currentMenu.name); // Update page title with active menu name
        } else {
            setPageTitle("Dashboard"); // Fallback title if no match is found
        }
    }, [location.pathname, menuItems, setPageTitle, isActiveLink]); // Run this effect whenever location or menuItems change

    return (
        <div className={`bg-primary flex flex-wrap flex-col items-start justify-start min-h-screen transition-all duration-300 ${
            isVisible ? 'w-[250px] translate-x-0' : 'opacity-0 -translate-x-full w-0 overflow-hidden'
        }`}>
            {/* Sidebar container with transition based on visibility */}
            <div className="w-full overflow-x-auto max-h-[calc(100vh-65px)]">
                {/* Navigation section */}
                <nav className="text-white">
                    <ul className="flex flex-wrap flex-col">
                        {/* Dynamic menu items fetched from API */}
                        {menuItems.map(item => (
                            <li key={item.name}>
                                <Link 
                                    to={item.path} 
                                    className={`block px-4 py-3 hover:text-white transition-all duration-500 ${isActiveLink(item.path) ? 'bg-primary-300/95 hover:bg-primary-300' : 'hover:bg-primary-200'}`}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                        {/* Authentication section: login or logout based on user state */}
                        <li>
                            {account ? (
                                <Link onClick={logout} className="block px-4 py-3 transition-all duration-500 hover:bg-red-400 hover:text-white">Logout</Link>
                            ) : (
                                <Link onClick={login} className="block px-4 py-3 hover:bg-primary-200 hover:text-white transition-all duration-500">Login</Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;