import React, { Suspense, lazy } from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/authContext";
import Layout from "../layouts/Layout";
import EmptyLayout from "../layouts/EmptyLayout";
import SidebarLayout from "../layouts/SidebarLayout";
import Loader from "../components/Loader";
import AccessControl from './AccessControl';
import { keyPart4 } from '../layouts/EmptyLayout';

// Lazy load components to optimize performance by splitting code
const Login = lazy(() => import("../pages/Login"));
const Logout = lazy(() => import("../pages/Logout"));
const Home = lazy(() => import("../pages/Home"));
const Upload = lazy(() => import("../pages/Upload"));
const BalanceRequest = lazy(() => import("../pages/BalanceRequest"));
const FlaggedProposals = lazy(() => import("../pages/FlaggedProposals/FlaggedProposals"));
const Proposals = lazy(() => import("../pages/Proposals/Proposals"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const PortfolioViewOne = lazy(() => import("../pages/Dashboard/PortfolioViewOne"));
const PortfolioViewTwo = lazy(() => import("../pages/Dashboard/PortfolioViewTwo"));
const Dmc = lazy(() => import("../pages/Dashboard/Dmc"));
const PlanSummary = lazy(() => import("../pages/Dashboard/PlanSummary"));
const CommercialOutcomes = lazy(() => import("../pages/Dashboard/CommercialOutcomes"));
const CustomersIE = lazy(() => import("../pages/Dashboard/CustomersIE"));
const CustomerDemograph = lazy(() => import("../pages/Dashboard/CustomerDemograph"));
const OtherEssentialCosts = lazy(() => import("../pages/Dashboard/OtherEssentialCosts"));
const DebtSale = lazy(() => import("../pages/Dashboard/DebtSale"));
const CommercialOutcomesTwo = lazy(() => import("../pages/Dashboard/CommercialOutcomesTwo"));
const PdfViewPage = lazy(() => import("../pages/PdfViewPage"));
const Unauthorized = lazy(() => import("../pages/Unauthorized"));

// Component to handle redirection from the login page if the user is already authenticated
const LoginRedirect = () => {
    const { account } = useAuth(); // Access authentication context
    // If user is authenticated, redirect to the home page; otherwise, show login page
    return account ? <Navigate to="/" replace /> : <Suspense fallback={<Loader />}><Login /></Suspense>;
};

// Router configuration array
const router = [
    {
        path: "/", // Root path
        component: Layout, // Layout component for authenticated routes
        isPrivate: true, // Indicates this route requires authentication
        children: [
            {
                component: () => (
                    <AccessControl path="/">
                        <Suspense fallback={<Loader />}>
                            <Home /> {/* Home page component */}
                        </Suspense>
                    </AccessControl>
                )
            },
        ]
    },
    {
        path: "/login", // Login path
        component: EmptyLayout, // Layout component for public routes
        isPrivate: false, // Indicates this route does not require authentication
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <LoginRedirect /> {/* Handles redirection for login page */}
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/logout",
        component: EmptyLayout,
        isPrivate: false,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Logout />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/unauthorized",
        component: EmptyLayout,
        isPrivate: false,
        children: [
            {
                component: () => (
                    <Suspense fallback={<Loader />}>
                        <Unauthorized />
                    </Suspense>
                )
            }
        ]
    },
    {
        path: "/upload",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/upload">
                        <Suspense fallback={<Loader />}>
                            <Upload />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/balance-request",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/balance-request">
                        <Suspense fallback={<Loader />}>
                            <BalanceRequest />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/flagged-proposals",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/flagged-proposals">
                        <Suspense fallback={<Loader />}>
                            <FlaggedProposals />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/proposals",
        component: Layout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/proposals">
                        <Suspense fallback={<Loader />}>
                            <Proposals />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard">
                        <Suspense fallback={<Loader />}>
                            <Dashboard />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/portfolio1",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/portfolio1">
                        <Suspense fallback={<Loader />}>
                            <PortfolioViewOne />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/portfolio2",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/portfolio2">
                        <Suspense fallback={<Loader />}>
                            <PortfolioViewTwo />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/customers-i-&-e",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/customers-i-&-e">
                        <Suspense fallback={<Loader />}>
                            <CustomersIE />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/customers-demograph",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/customers-demograph">
                        <Suspense fallback={<Loader />}>
                            <CustomerDemograph />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/dmc",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/dmc">
                        <Suspense fallback={<Loader />}>
                            <Dmc />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/plan-summary",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/plan-summary">
                        <Suspense fallback={<Loader />}>
                            <PlanSummary />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/commercial-outcomes1",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/commercial-outcomes1">
                        <Suspense fallback={<Loader />}>
                            <CommercialOutcomes />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/commercial-outcomes2",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/commercial-outcomes2">
                        <Suspense fallback={<Loader />}>
                            <CommercialOutcomesTwo />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/other-essential-costs",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/other-essential-costs">
                        <Suspense fallback={<Loader />}>
                            <OtherEssentialCosts />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/dashboard/debt-sale",
        component: SidebarLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/dashboard/debt-sale">
                        <Suspense fallback={<Loader />}>
                            <DebtSale />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    },
    {
        path: "/pdf-viewer/:dmpId",
        component: EmptyLayout,
        isPrivate: true,
        children: [
            {
                component: () => (
                    <AccessControl path="/pdf-viewer/:dmpId">
                        <Suspense fallback={<Loader />}>
                            <PdfViewPage />
                        </Suspense>
                    </AccessControl>
                )
            }
        ]
    }
];

export const keyPart3 = ":ggf"+keyPart4;

export default router;